var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_sales_order_tracking") } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                layout: "horizontal",
                "label-align": "left",
                model: _vm.formModel,
                "wrapper-col": { span: 4 },
                "label-col": { span: 3 }
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_so_date"), prop: "date" } },
                [
                  _c("a-range-picker", {
                    staticClass: "w-100",
                    attrs: {
                      format: _vm.DEFAULT_DATE_FORMAT,
                      placeholder: [
                        _vm.$t("lbl_start_date"),
                        _vm.$t("lbl_end_date")
                      ]
                    },
                    model: {
                      value: _vm.formModel.date,
                      callback: function($$v) {
                        _vm.$set(_vm.formModel, "date", $$v)
                      },
                      expression: "formModel.date"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_type"), prop: "soType" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        value: _vm.formModel.soType,
                        loading: _vm.loading.soType,
                        dropdownMatchSelectWidth: false,
                        "show-search": "",
                        "allow-clear": "",
                        "option-filter-prop": "children",
                        "filter-option": _vm.useLocalFilter
                      },
                      on: { change: _vm.onChangeSalesType }
                    },
                    _vm._l(_vm.optsSoType, function(opt) {
                      return _c(
                        "a-select-option",
                        { key: opt.key, attrs: { value: opt.value } },
                        [
                          _c("a-tooltip", [
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(" " + _vm._s(opt.label) + " ")]
                            ),
                            _vm._v(" " + _vm._s(opt.label) + " ")
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_sales_order"), prop: "soId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        loading: _vm.loading.salesOrder,
                        dropdownMatchSelectWidth: false,
                        "show-search": "",
                        "allow-clear": "",
                        "filter-option": false
                      },
                      on: { search: _vm.onSearchSalesOrder },
                      model: {
                        value: _vm.formModel.soId,
                        callback: function($$v) {
                          _vm.$set(_vm.formModel, "soId", $$v)
                        },
                        expression: "formModel.soId"
                      }
                    },
                    _vm._l(_vm.optsSalesOrder, function(opt) {
                      return _c(
                        "a-select-option",
                        { key: opt.key, attrs: { value: opt.value } },
                        [
                          _c("a-tooltip", [
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(" " + _vm._s(opt.label) + " ")]
                            ),
                            _vm._v(" " + _vm._s(opt.label) + " ")
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { offset: 3 } } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.resetForm } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.loading.find
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.tables, function(table) {
            return [
              _c(
                "div",
                { key: table.key },
                [
                  _c("a-divider", { attrs: { direction: "left" } }, [
                    _vm._v(" " + _vm._s(table.title) + " ")
                  ]),
                  _c("a-table", {
                    attrs: {
                      "data-source": table.dataSource,
                      columns: table.columns,
                      loading: _vm.loading.find,
                      pagination: {
                        showTotal: function(total) {
                          return _vm.$t("lbl_total_items", { total: total })
                        }
                      },
                      size: "small"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "date",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("date")(text)))
                            ])
                          }
                        },
                        {
                          key: "salesOrderNumber",
                          fn: function(text, record) {
                            return _c(
                              "a-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleDrawer(record, table.type)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(record.salesOrderNumber || "-") +
                                    " "
                                ),
                                _c("a-icon", { attrs: { type: "select" } })
                              ],
                              1
                            )
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _c(
        "a-drawer",
        {
          attrs: {
            title: _vm.drawer.record ? _vm.drawer.record.salesOrderNumber : "",
            placement: "bottom",
            visible: _vm.drawer.show,
            height: "576px"
          },
          on: { close: _vm.onCloseDrawer }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex", align: "middle" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-descriptions",
                    { attrs: { layout: "vertical", size: "small", column: 6 } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_so_number") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.drawer.record
                                  ? _vm.drawer.record.salesOrderNumber
                                  : "-"
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_customer") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.drawer.record
                                  ? _vm.drawer.record.customerName
                                  : "-"
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_date") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.drawer.record
                                    ? _vm.drawer.record.salesOrderDate
                                    : ""
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_sales_type") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.drawer.meta
                                  ? _vm.drawer.meta.salesType
                                  : "-"
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_so_status") } },
                        [
                          _c(
                            "a-tag",
                            { attrs: { color: _vm.drawer.tagColor } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.drawer.meta
                                      ? _vm.drawer.meta.states
                                      : "-"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_created_by") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.drawer.record
                                  ? _vm.drawer.record.createdBy
                                  : "-"
                              ) +
                              " "
                          )
                        ]
                      ),
                      !_vm.drawer.isSO
                        ? _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_invoice_status") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.drawer.record &&
                                      _vm.drawer.record.invoice
                                      ? _vm.drawer.record.invoice
                                      : "-"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      "data-source": _vm.drawer.meta
                        ? _vm.drawer.meta.dataSource
                        : [],
                      columns: _vm.columnsProduct,
                      loading: _vm.loading.detailSO,
                      pagination: {
                        showTotal: function(total) {
                          return _vm.$t("lbl_total_items", { total: total })
                        }
                      },
                      size: "small"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("lbl_sales_order")))
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "number",
                        fn: function(text) {
                          return _c("span", {}, [
                            _vm._v(_vm._s(_vm._f("qty")(text)))
                          ])
                        }
                      },
                      {
                        key: "nullable",
                        fn: function(text) {
                          return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                        }
                      },
                      {
                        key: "productCode",
                        fn: function(text, record) {
                          return _c("span", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  record.productCode || record.unitCode || "-"
                                ) +
                                " "
                            )
                          ])
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm.drawer.isDO
                ? _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("a-table", {
                        attrs: {
                          "data-source": _vm.drawer.listDo,
                          columns: _vm.columnsDo,
                          loading: _vm.loading.detailSO,
                          pagination: {
                            showTotal: function(total) {
                              return _vm.$t("lbl_total_items", { total: total })
                            }
                          },
                          size: "small",
                          "row-key": "doId"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("lbl_delivery_order")))
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "operation",
                              fn: function(text, record) {
                                return [
                                  _c(
                                    "a-button-group",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "sales.delivery-order.edit",
                                              params: { id: record.doId }
                                            }
                                          }
                                        },
                                        [
                                          _c("a-button", {
                                            attrs: { icon: "form" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("a-button", {
                                        attrs: {
                                          type: "primary",
                                          icon: "printer",
                                          loading: _vm.loading.print
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.printDo(record.doId)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3847143785
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.drawer.isSO,
                      expression: "drawer.isSO"
                    }
                  ],
                  attrs: { span: 24, align: "end" }
                },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: _vm.$t("lbl_validation_redirect"),
                            "ok-text": _vm.$t("lbl_yes"),
                            "cancel-text": _vm.$t("lbl_no")
                          },
                          on: { confirm: _vm.handleCreateDO }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.drawer.allowCreateDO,
                                  expression: "drawer.allowCreateDO"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_create_do")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.print
                          },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }