











































































































































































































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import {
  useBlob,
  useDate,
  useDeliveryOrder,
  useFindMasterType,
  useLocalFilter,
  useMapMasterTypeToOption,
  usePickingList,
  useSalesOrder,
  useSalesOrderTracking,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { MASTER_TYPE_REF } from "@/models/enums/master-type.enum";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  SalesDoResponseDto,
  SalesOrderLineResponseDto,
  SalesOrderResponseDto,
} from "@/models/interface/sales-order";
import {
  ISalesOrderTrackingGroupLineResponseDTO,
  ISalesOrderTrackingGroupResponseDTO,
  RequestParamsGetListSalesOrderTracking,
} from "@/models/interface/SalesOrderTracking.interface";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import printJS from "print-js";
import { Component, Mixins, Ref } from "vue-property-decorator";

type RowDo = {
  no: number;
} & SalesDoResponseDto;
type RowSalesOrderProduct = {
  key: number;
  no: number;
} & SalesOrderLineResponseDto;
type DrawerMetaData = SalesOrderResponseDto & {
  dataSource: RowSalesOrderProduct[];
  doLines: RowDo[];
};
type Row = {
  key: number;
} & ISalesOrderTrackingGroupLineResponseDTO;
type Tracker = "so" | "do" | "invoice";
type TableColumn = Partial<{
  title: string;
  dataIndex: string;
  scopedSlots: Record<string, string>;
  ellipsis: boolean;
}>;
type Table = {
  dataSource: Row[];
  title: string;
  type: Tracker;
  key: number;
  columns: Array<TableColumn>;
};

@Component
export default class SalesOrderTracking extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useLocalFilter = useLocalFilter;

  @Ref("form") form!: FormModel;

  optsFilter: Option[] = [];
  optsSalesOrder: Option[] = [];
  optsSoType: Option[] = [];
  formModel = {
    soId: "",
    date: null as Moment[] | null,
    soType: "",
  };
  loading = {
    soType: false,
    detailSO: false,
    print: false,
    salesOrder: false,
    find: false,
    filter: false,
  };

  columns = [
    {
      title: this.$t("lbl_so_date").toString(),
      dataIndex: "createdDate",
      width: 150,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_so_number").toString(),
      dataIndex: "salesOrderNumber",
      width: 200,
      scopedSlots: { customRender: "salesOrderNumber" },
    },
    {
      title: this.$t("lbl_customer").toString(),
      dataIndex: "customerName",
    },
    {
      title: this.$t("lbl_created_by").toString(),
      dataIndex: "createdBy",
    },
  ] as const;

  tables: Table[] = [
    {
      dataSource: [],
      title: this.$t("lbl_sales_order").toString(),
      key: 0,
      type: "so",
      columns: [...this.columns],
    },
    {
      dataSource: [],
      title: this.$t("lbl_delivery_order").toString(),
      key: 1,
      type: "do",
      columns: [...this.columns],
    },
    {
      dataSource: [],
      title: this.$t("lbl_invoice").toString(),
      key: 2,
      type: "invoice",
      columns: [
        ...this.columns,
        {
          title: this.$t("lbl_invoice_status").toString(),
          dataIndex: "invoice",
          ellipsis: true,
        },
      ],
    },
  ];
  columnsDo = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      width: "75px",
    },
    {
      title: this.$t("lbl_delivery_order_number"),
      dataIndex: "doNumber",
      width: "200px",
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      align: "left",
      scopedSlots: { customRender: "operation" },
    },
  ];
  columnsProduct = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      scopedSlots: { customRender: "productCode" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_qty_delivered"),
      dataIndex: "qtyDelivery",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_qty_outstanding"),
      dataIndex: "qtyOutstanding",
      scopedSlots: { customRender: "number" },
    },
  ];

  drawer = {
    show: false,
    record: null as Row | null,
    meta: null as DrawerMetaData | null,
    type: null as Tracker | null,
    get isSO(): boolean {
      return this.type === "so";
    },
    get isDO(): boolean {
      return this.type === "do";
    },
    get soId(): string {
      return this.meta ? this.meta.id : "";
    },
    get hasOutstanding(): boolean {
      return !!this.meta?.dataSource.find(item => item.qtyOutstanding > 0);
    },
    get allowCreateDO(): boolean {
      return this.meta?.salesType !== "Others" && this.allowCreateByStatus;
    },
    get tagColor(): "red" | "green" {
      const red = [SALES_ORDER_STATUS.CANCELLED, SALES_ORDER_STATUS.CLOSED];
      if (this.meta && red.includes(this.meta.states)) {
        return "red";
      }

      return "green";
    },
    get allowCreateByStatus(): boolean {
      const allowCreate = [
        SALES_ORDER_STATUS.SUBMITTED,
        SALES_ORDER_STATUS.PARTIAL_DELIVERED,
      ];
      if (this.meta) return allowCreate.includes(this.meta.states);
      return true;
    },
    get listDo(): SalesDoResponseDto[] {
      return this.meta ? this.meta.doLines : [];
    },
  };

  created(): void {
    this.fetchSoType();
    this.fetchFilters();
    this.fetchSalesOrder(
      new RequestQueryParams(this.buildSearchSo({ docNumber: "", soType: "" }))
    );
  }

  mounted(): void {
    this.findData();
  }

  findData(): void {
    const params = this.buildQuery();
    this.fetchData(params);
  }

  onSubmit(): void {
    this.findData();
  }

  /**
   * @description build query params
   * date params = enum CUSTOM, THIS WEEK, THIS MONTH, TODAY
   * search params = string contains createdDate if date params
   * is CUSTOM
   */
  buildQuery(): RequestParamsGetListSalesOrderTracking {
    const { soId, date, soType } = this.formModel;
    const params: RequestParamsGetListSalesOrderTracking = {
      date: "CUSTOM",
    };
    const { toStartDay, toEndDay } = useDate();
    const builder = new SearchBuilder();
    const q: string[] = []; // query string
    if (soId) {
      q.push(builder.push(["salesOrderId", soId]).build());
      builder.destroy();
    }

    if (date && date.length) {
      const [start, end] = date;
      const startDay = toStartDay(start).utc().format();
      const endDay = toEndDay(end).utc().format();
      q.push(
        builder
          .push(["createdDate", startDay], { het: true })
          .and()
          .push(["createdDate", endDay], { let: true })
          .build()
      );
      builder.destroy();
    }

    if (soType) {
      q.push(builder.push(["salesType", soType]).build());
      builder.destroy();
    }

    if (q.length) {
      params.search = q.join(builder.AND);
    }

    return params;
  }

  assignSOTable({ salesOrder }: ISalesOrderTrackingGroupResponseDTO): void {
    const IDX_SO = 0;
    this.tables[IDX_SO].dataSource = salesOrder.map<Row>((item, i) => ({
      key: i,
      ...item,
    }));
  }

  assignDOTable({ deliveryOrder }: ISalesOrderTrackingGroupResponseDTO): void {
    const IDX_DO = 1;
    this.tables[IDX_DO].dataSource = deliveryOrder.map<Row>((item, i) => ({
      key: i,
      ...item,
    }));
  }

  assignInvoiceTable({ invoiceAr }: ISalesOrderTrackingGroupResponseDTO): void {
    const IDX_INVOICE = 2;
    this.tables[IDX_INVOICE].dataSource = invoiceAr.map<Row>((item, i) => ({
      key: i,
      ...item,
    }));
  }

  fetchData(params?: RequestParamsGetListSalesOrderTracking): void {
    const { findAll } = useSalesOrderTracking();
    this.loading.find = true;
    findAll(params)
      .then(response => {
        this.assignSOTable(response);
        this.assignDOTable(response);
        this.assignInvoiceTable(response);
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  fetchFilters(): void {
    this.loading.filter = true;
    useFindMasterType(MASTER_TYPE_REF.SALES_TRACKING_DATE_FILTER)
      .then(response => {
        const options = useMapMasterTypeToOption(response);
        this.optsFilter = options.map(item => ({
          ...item,
          label: this.$t(`lbl_${item.value.toLowerCase()}`).toString(),
        }));
      })
      .finally(() => {
        this.loading.filter = false;
      });
  }

  fetchSoType(): void {
    const { findAllSoType } = useSalesOrder();
    this.loading.soType = true;
    findAllSoType()
      .then(response => {
        this.optsSoType = response
          .filter(
            item =>
              item.value.toUpperCase() === "ASSET SALE" ||
              item.value.toUpperCase() === "PRODUCT SALE"
          )
          .map<Option>(item => ({
            label: item.value,
            value: item.value,
            key: item.id,
          }));
      })
      .finally(() => {
        this.loading.soType = false;
      });
  }

  onSearchSalesOrder(value = ""): void {
    const { soType } = this.formModel;
    const params = new RequestQueryParams();
    params.search = this.buildSearchSo({ soType, docNumber: value });
    debounce(() => {
      this.fetchSalesOrder(params);
    });
  }

  fetchSalesOrder(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useSalesOrder();

    this.loading.salesOrder = true;
    findAll(params)
      .then(response => {
        this.optsSalesOrder = toOptions(response.data || []);
      })
      .finally(() => {
        this.loading.salesOrder = false;
      });
  }

  /**
   * @description set meta data in drawer state
   * used by drawer component to show detail
   * sales order
   */
  buildDrawerMetaData(detailSO: SalesOrderResponseDto): DrawerMetaData {
    return {
      ...detailSO,
      dataSource: detailSO.salesOrderLines.map<RowSalesOrderProduct>(
        (item, i) => ({
          ...item,
          key: i,
          no: i + 1,
        })
      ),
      doLines: detailSO.doResponseDTOS.map<RowDo>((item, i) => ({
        ...item,
        no: i + 1,
      })),
    };
  }

  async fetchSalesOrderById(soId: string): Promise<SalesOrderResponseDto> {
    const { findById } = useSalesOrder();
    this.loading.detailSO = true;
    return findById(soId)
      .then(response => response)
      .finally(() => {
        this.loading.detailSO = false;
      });
  }

  async toggleDrawer(record: Row, type: Tracker): Promise<void> {
    try {
      this.drawer.show = true;
      this.drawer.type = type;
      this.drawer.record = record;
      const detailSO = await this.fetchSalesOrderById(record.salesOrderId);
      this.drawer.meta = this.buildDrawerMetaData(detailSO);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  onCloseDrawer(): void {
    this.drawer.show = false;
    this.drawer.type = null;
    this.drawer.record = null;
    this.drawer.meta = null;
  }

  handlePrint(): void {
    const { print } = usePickingList();
    const { toObjectUrl } = useBlob();
    const [data] = this.drawer.meta?.pickingList || [];
    if (!data) return;
    this.loading.print = true;
    print([data.pickingListId])
      .then(response => {
        printJS(toObjectUrl(response));
      })
      .finally(() => {
        this.loading.print = false;
      });
  }

  handleCreateDO(): void {
    const { meta } = this.drawer;
    if (!this.drawer.hasOutstanding) {
      this.showNotifWarning("notif_qty_outstanding_zero_for_document-x", {
        docNumber: meta?.documentNumber || "",
      });
      return;
    }
    this.$router.push({
      name: "sales.delivery-order.create",
      query: { soId: meta?.id || "", from: "dashboard" },
    });
  }

  resetForm(): void {
    this.form.resetFields();
    this.formModel.date = [];
  }

  onChangeSalesType(value = ""): void {
    const params = new RequestQueryParams();

    this.formModel.soType = value;
    this.formModel.soId = "";

    params.search = this.buildSearchSo({
      docNumber: "",
      soType: this.formModel.soType,
    });
    this.fetchSalesOrder(params);
  }

  buildSearchSo(field: { docNumber: string; soType: string }): string {
    const builder = new SearchBuilder();
    const defaultQ = builder
      .push(["state", "Draft"], { not: true })
      .and()
      .push(["state", "Cancelled"], { not: true })
      .build();
    const q: Array<string> = [defaultQ];

    if (field.docNumber) {
      q.push(
        builder
          .push(["documentNumber", field.docNumber], { like: "both" })
          .build()
      );
    }

    if (field.soType) {
      q.push(builder.push(["salesType", field.soType]).build());
    } else {
      // default query sales type by Product Sale or Asset Sale
      q.unshift(
        builder
          .push(["salesType", "Product Sale"])
          .or()
          .push(["salesType", "Asset Sale"])
          .build()
      );
    }

    return q.join(builder.AND);
  }

  printDo(id: string): void {
    const { print } = useDeliveryOrder();
    const { toObjectUrl } = useBlob();
    this.loading.print = true;
    print(id)
      .then(res => {
        printJS(toObjectUrl(res));
      })
      .finally(() => {
        this.loading.print = false;
      });
  }
}
